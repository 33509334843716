@import '../../../../assets/style/mixins';

.root {
  background-color: #fff;
  padding: 24px;
}

.button {
  display: block;
  margin: 0 auto;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col {
  display: flex;
  flex-wrap: wrap;
  & span:first-child {
    margin-right: 4px;
  }
}

.root [class].skeletonParagraph {
  display: flex;

  & > li {
    margin: 0 15px 0 0;
    width: 100%;
  }
}

@include media-custom(600) {
  .root [class].skeletonParagraph,
  .col,
  .row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .root [class].skeletonParagraph > li,
  .col:not(:last-child) {
    margin-bottom: 24px;
  }
}
