@import '../../../assets/style/variables';

[class].root {
  max-width: 260px;

  & :global {
    .ant-popover-arrow {
      bottom: -8px;
    }

    .ant-popover-inner-content {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 22px;
    }

    .ant-popover-inner {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }

    .ant-popover {
      z-index: $zIndex-popover;
    }
  }

  &:global(.ant-popover-placement-bottom),
  &:global(.ant-popover-placement-bottomLeft),
  &:global(.ant-popover-placement-bottomRight),
  &:global(.ant-popover-placement-top),
  &:global(.ant-popover-placement-topLeft),
  &:global(.ant-popover-placement-topRight) {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.icon {
  width: 14px;
  height: 14px;
  color: #8c8c8c;
}
