@import '../../../assets/style/variables';

[class].menu {
  &Item {
    padding: 0 16px !important;
    margin: 8px 0;

    &:global(.ant-menu-title-content) {
      margin-left: 10px;
    }

    &Collapse {
      padding: 0;
      margin: 8px 0;

      & :global(.ant-menu-submenu-title) {
        margin-bottom: 8px;
        padding: 0 0 0 16px !important;
      }

      &Item {
        padding-left: 40 !important;
        margin: 0;

        &:nth-last-child(n + 2) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &Link {
    font-size: 14px;
    line-height: 1.571;
    text-decoration: none;
  }
}

[class].horizontal {
  padding: 16px 0 0 8px;
  line-height: 1;

  &Item {
    padding: 0 !important;
    margin: 0 16px;

    &:after {
      display: none !important;
    }
  }

  &Link {
    padding: 10px 0;
    font-size: 16px;
    line-height: 2.5;
    text-decoration: none;

    &Active {
      border-bottom: 2px solid $primary;
      color: $primary !important;
    }
  }
}
