.root {
  dl {
    padding: 0;
    margin: 0;

    dt {
      float: left;
      font-weight: normal;
      margin-right: 5px;
    }

    dd {
      font-weight: bold;
    }
  }

  :global {
    .ant-col {
      & > .ant-card {
        height: 100%;

        & > .ant-card-body {
          height: 100%;

          & > .ant-row {
            height: 100%;
          }
        }
      }
    }
  }
}
