@import '../../../../../assets/style/mixins';

.airdrop {
  display: flex;
  justify-content: flex-end;
}

@include media-custom(767) {
  .airdrop {
    justify-content: flex-start;
    margin-top: 16px;
  }
}
