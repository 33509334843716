@import '../../../../../assets/style/index';

.list {
  overflow: auto;
  max-height: 280px;
}

.title {
  color: $primary;
}
