.root {
  display: flex;
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 360px;

  form {
    width: 100%;
  }
}

.logo {
  margin-bottom: 40px;
}
