.form {
  max-width: 360px;
  margin: 0 auto;
}

[class].buttonItem {
  margin: 0;

  & :global(.ant-form-item-control-input-content) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
