@import '../../../assets/style/variables';

.root {
  height: $header-fixed-height;
  line-height: $header-fixed-height;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: $zIndex-header;
}

.menuIcon {
  color: #ffffff;
  cursor: pointer;
}

.drawer {
  :global(.ant-drawer-body) {
    padding: 0 0 8px 0;
    display: flex;
    flex-direction: column;
  }
}

.logout {
  margin-top: auto;
  text-align: left;
}

.opener {
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.user {
  display: inline-flex;
  align-items: center;

  :global(.ant-avatar) {
    margin-right: 8px;
  }
}
