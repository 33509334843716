@import './variables';
@import './mediaVariables.module';

@mixin media-tablet(
  $maxWidth: $media-tablet-max-width-landscape,
  $minWidth: $media-mobile-max-width-landscape
) {
  @media screen and (max-width: $maxWidth + 'px'),
    screen and (min-width: $minWidth + 'px') and (max-width: $maxWidth + 'px') and (orientation: landscape) {
    @content;
  }
}

@mixin media-phone(
  $maxWidth: $media-tablet-min-width - 1,
  $maxHeight: $media-mobile-max-height,
  $maxWidthLandscape: $media-mobile-max-width-landscape
) {
  @media screen and (max-width: $maxWidth  + 'px'),
    screen and (max-height: $maxHeight + 'px') and (max-width: $maxWidthLandscape + 'px') and (orientation: landscape) {
    @content;
  }
}

@mixin media-custom($width) {
  @media screen and (max-width: $width + 'px') {
    @content;
  }
}
