@import '../../../../assets/style/mixins';

.root :global(.ant-card-body),
.info {
  display: flex;
}

.root [class].skeletonParagraph {
  margin: 0;
}

.info {
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  min-width: 0;

  &Item {
    &:first-child {
      margin-bottom: 8px;
    }

    & > span:first-child {
      margin-right: 4px;
    }
  }
}

@include media-tablet() {
  .root :global(.ant-card-body) {
    flex-direction: column-reverse;
  }

  .info {
    flex-direction: row;
    margin-bottom: 24px;

    &Item {
      margin-bottom: 0;

      &:first-child {
        margin-right: 40px;
      }
    }
  }
}

@include media-phone() {
  .info {
    flex-direction: column;
  }
}
