@import '../../../assets/style/variables';

[class].form {
  & :global {
    .ant-form-item-label > label.ant-form-item-required::before {
      display: none;
    }

    .ant-input {
      color: $input-text;
    }
  }
}

.content {
  max-width: 750px;
  margin: 0 auto;
}
