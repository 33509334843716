@import '../../../assets/style/mixins';

$aside-width: 208px;

.container {
  padding-top: $header-fixed-height;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  min-width: 0;
}

.content {
  padding: 24px;
  width: calc(100% - #{$aside-width});
}

@include media-tablet() {
  .content {
    padding: 16px;
    width: 100%;
  }
}

.aside {
  overflow: auto;
  width: $aside-width;
  background: #ffffff;
  flex-shrink: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
