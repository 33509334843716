.item,
.button {
  padding: 0 !important;
}

.button {
  &Icon {
    font-size: 32px;
  }

  &Last {
    margin-left: 16px;
  }
}
