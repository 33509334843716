.form {
  &Item {
    width: 100%;
    max-width: 600px;
    padding: 0;
  }

  &Input {
    margin: 0;
  }
}
