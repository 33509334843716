@import '../../../assets/style/variables';

.row:hover > td {
  background-color: $table-row-hover !important;
  z-index: 0;
}

.draggable {
  cursor: grab;
  user-select: none;
}

.hidden {
  opacity: 0.5;
}

.button {
  &Add {
    border-bottom: 1px solid $border;
    padding: 16px;
  }

  &Wrapper {
    justify-content: center;
    margin: 24px 0 4px;
  }

  &Submit {
    padding: 8px 28px;
    line-height: 24px;
  }
}
