@import '../../../../../assets/style/index';

.root {
  overflow: auto;
  max-height: 280px;
}

.label {
  font-size: 12px;
  line-height: 22px;
  font-style: italic;
  color: $error;
}
