// VARS
$header-fixed-height: 48px;

// COLORS
$main-background: #e5e5e5;
$primary: #1890ff;
$input-text: #000;
$error: #f5222d;
$border: #f0f0f0;
$table-row-hover: #e6f7ff;

// Z-INDEX
$zIndex-header: 100;
$zIndex-popover: 50;
