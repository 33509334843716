@import '../../../assets/style/mixins';

.root {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 70px;
  width: 100%;

  &Container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &Item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 20px;
  }

  &Text {
    margin-right: 12px;
  }

  &Date {
    width: 100%;
  }
}

.root [class].skeletonParagraph {
  margin: 0;
}

// 992 === ant grid MD breakpoint
@include media-custom(992) {
  .root {
    flex-wrap: wrap;

    justify-content: center;

    &Container {
      margin-bottom: 24px;
    }
  }
}

@include media-phone() {
  .root {
    &Container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }

    &Item {
      margin: 0;
      justify-content: flex-end;

      &:nth-child(2) {
        margin-top: 24px;
        padding-left: 20px;
      }
    }
  }
}
