.root {
  display: flex;
  align-items: center;

  &Text {
    margin-right: 4px;
  }
}

[class].popover {
  max-width: 236px;
}
