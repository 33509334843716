.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details,
.filter {
  margin-bottom: 24px;
}
