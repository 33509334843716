@import 'variables';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  background: $main-background;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}

#root {
  display: flex;
  width: 100%;
  min-height: 100%;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
