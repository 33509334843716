.root {
  position: relative;
}

[class].nav {
  position: absolute;
  top: -24px;
  left: -24px;
  right: -24px;
}

.wrapper {
  padding-top: 56px;
  flex: 1;
}
